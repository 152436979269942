<template>
  <template v-for="heroSection in item.fields.heroSections">
    <component
      :is="getHeroSectionComponent(heroSection)"
      v-if="getHeroSectionComponent(heroSection)"
      :key="heroSection.sys.id"
      :item="heroSection"
      :color-mode="pageTheme"
    />
  </template>
  <component
    :is="getHeroSectionComponent(item.fields.heroSection)"
    v-if="getHeroSectionComponent(item.fields.heroSection)"
    :item="item.fields.heroSection"
    :color-mode="pageTheme"
  />
</template>

<script setup lang="ts">
import type { Entry } from 'contentful';
import type {
  IContentPage,
  IContentPageMultiLocale,
  IProductSeriesPage,
  ISolutionsArticlePage
} from '~/lib/ContentfulService';
import { pageToDefaultThemeMap } from '~/utils/pageToDefaultThemeMap';

interface Props {
  item:
    | IContentPage
    | ISolutionsArticlePage
    | IContentPageMultiLocale
    | IProductSeriesPage;
}

const props = defineProps<Props>();

const pageTheme = computed(
  () =>
    props.item.fields.metadata?.fields?.pageTheme ??
    pageToDefaultThemeMap[props.item.sys.contentType.sys.id]
);

function getHeroSectionComponent(entry?: Entry<unknown>) {
  switch (entry?.sys.contentType?.sys.id) {
    case 'heroArticleSection':
      return 'ContentfulHeroArticleSection';
    case 'heroProductSection':
      return 'ContentfulHeroProductSection';
    case 'heroOverviewSection':
      return 'ContentfulHeroOverviewSection';
  }
}
</script>
